import React from "react";
import { useState } from "react";
function LoginForm({ Login, error }) {
  const [details, setDetails] = useState({
    userName: "",
    email: "",
    password: "",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    Login(details);
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="form">
        <h2>Login</h2>
        {error !== "" ? <div className="error">{error}</div> : ""}
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            className="input"
            type="text"
            name="userName"
            id="name"
            onChange={(e) =>
              setDetails({ ...details, userName: e.target.value })
            }
            value={details.userName}
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setDetails({ ...details, email: e.target.value })}
            value={details.name}
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            className="input"
            type="password"
            name="password"
            id="password"
            onChange={(e) =>
              setDetails({ ...details, password: e.target.value })
            }
            value={details.password}
          />
        </div>
        <button className="button" type="submit">
          LOGIN
        </button>
      </div>
    </form>
  );
}

export default LoginForm;
